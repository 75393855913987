@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content{
    height: 100%;
    width: 100%;
    font-family: 'Source Sans 3', sans-serif;
}

.app{
    display: flex;
    position: relative;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: linear-gradient(to right,rgb(226, 189, 129), rgb(255, 157, 0));
}
